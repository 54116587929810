import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Menu,
  MenuItem,
  MenuList,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";

const FooterWebsiteInfo = () => {
  const websiteInfoItems = [
    {
      text: "@ 2024 Powered by David Grig",
      link: "",
    },
    {
      text: "Sitemap",
      link: "",
    },
    {
      text: "terms & conditions",
      link: "",
    },
    //   {
    // text: "@ 2024 Powered by David Grig",
    // link: "",
    //   },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        // boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <List
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          // width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // mx: "auto",
        }}
      >
        {websiteInfoItems.map((infoItem, index) => (
          <ListItem
            sx={{
              boxShadow: 0,
              // px: 0,
              borderColor: grey[300],
              // border: 1,
              // width: "100%",
              mb: 2,
              textAlign: "center",

              width: "max-content",
              // display: "block",
              // borderRadius: 4,
              // bgcolor: "",
            }}
            key={infoItem.text}
            disablePadding
          >
            <Link
              to={infoItem.link}
              style={{
                textDecoration: "none",
                color: "primary.contrastText",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <ListItemButton>
                <ListItemIcon
                  sx={{ width: 10, height: 10, textAlign: "center" }}
                >
                  {infoItem.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                  primary={
                    <Typography
                      sx={{
                        color: "primary.contrastText",
                        textAlign: "center",
                      }}
                      variant="subtitle1"
                    >
                      {infoItem.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Link>
            {/* <Divider sx={{ width: "100%" }} /> */}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FooterWebsiteInfo;
