import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { IconButton, Link } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const socialItems = [
  {
    name: "facebook",
    icon: <FacebookIcon sx={{ color: "primary.contrastText" }} />,
    link: "https://www.facebook.com/architectumarmenia?mibextid=ZbWKwL",
  },
  {
    name: "instagram",
    icon: <InstagramIcon sx={{ color: "primary.contrastText" }} />,
    link: "https://www.instagram.com/architectum_armenia?igsh=MWgzeXk3cDNjZ3BiOA==",
  },
  {
    name: "linkedIn",
    icon: <LinkedInIcon sx={{ color: "primary.contrastText" }} />,
    link: "https://www.linkedin.com/company/architectum-armenia/",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function FooterSocialBar() {
  return (
  
      <Stack direction="row"  spacing={0} sx={{mt: 4, mb: 2,  bgcolor: "#111", pl: 0 }}>
        {socialItems.map((item, index) => {
          return (
            <Item sx={{ boxShadow: 0, bgcolor: "#111", }}>
              <IconButton>
                <Link target="_blank" href={item.link}>
                  {item.icon}
                </Link>
              </IconButton>
            </Item>
          );
        })}
      </Stack>
  );
}
