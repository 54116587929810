import React from "react";

//   import React from "react";

import { useFormik } from "formik";
import { Box, Button, Container, TextField } from "@mui/material";
//   import { useDispatch } from "react-redux";
import loginFormSchema from "./loginFormSchema";


const LoginForm = ({handleLogin}) => {
  //   const dispatch = useDispatch();

  

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormSchema,

    onSubmit: (values) => {
      // dispatch(loginUser(values));
      handleLogin(values.email, values.password);
    },
  });

  return (
    <Container maxWidth="sm">
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", height: "100%" }}
      >
        <TextField
          size="small"
          label="Email"
          variant="standard"
          required
          name="email"
          type="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          sx={{ mb: 3, width: "100%" }}
        />
        {formik.errors.email && formik.touched.email ? (
          <Box sx={{ minHeight: "max-content" }}>{formik.errors.email}</Box>
        ) :null 
          // <Box sx={{ minHeight: "max-content" }}>{formik.errors.email}</Box>
        }

        <TextField
          size="small"
          label="Password"
          variant="standard"
          required
          name="password"
          type="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          sx={{ mb: 5, width: "100%" }}
        />
        {formik.errors.password && formik.touched.password ? (
          <Box>{formik.errors.password}</Box>
        ) : null
          // <Box>{formik.errors.password}</Box>
        }
        <Button
          sx={{ width: "100%" }}
          variant="outlined"
          color="info"
          type="submit"
        >
          Login
        </Button>
      </form>
    </Container>
  );
};

export default LoginForm;
