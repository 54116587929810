import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import ProjectsTabs from './ProjectsTabs';
import ProjectsCarousel from './ProjectsCarousel';
import CustomPagination from '../gallery/CustomPagination';

const ProjectsPage = () => {
  return (
    <Box sx={{mt: 20}}>
      <Container maxWidth={"xl"}>
        <Typography variant="h4" sx={{ textAlign: "left", mb: 10 }}>
          Projects
        </Typography>
        <ProjectsCarousel />

        <ProjectsTabs />
      </Container>
    </Box>
  );
}

export default ProjectsPage