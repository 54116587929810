import { Box } from "@mui/material";
import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { FaAmazon } from "react-icons/fa";
import { SiSamsung } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { FaMicrosoft } from "react-icons/fa";
import { SiAliexpress } from "react-icons/si";
import { SiNokia } from "react-icons/si";
import { FaEbay } from "react-icons/fa";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "../../shared/styles/SwiperCustomStyles.css"
// import AppleIcon from "@mui/icons-material/Apple";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";

// Import Swiper styles
import "swiper/css";

const partnersData = [
  {
    icon: <FaAmazon style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },

  {
    icon: <SiSamsung style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: <FaApple style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: <FaMicrosoft style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: <SiAliexpress style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: <SiNokia style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: <FaEbay style={{ fontSize: 100, color: "grey" }} />,
    title: "",
    body: "",
  },
  {
    icon: "",
    title: "",
    body: "",
  },
];

const PartnersCarousel = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={5}
          slidesPerView={6}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {partnersData.map((partner, index) => {
            return <SwiperSlide>{partner.icon}</SwiperSlide>;
          })}
        </Swiper>
      </Box>

      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          style={{padding: 4}}
        >
          {partnersData.map((partner, index) => {
            return (
              <SwiperSlide style={{ padding: 4 }}>{partner.icon}</SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </>
  );
};

export default PartnersCarousel;
