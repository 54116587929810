import { Box, Container } from "@mui/material";
import React from "react";
import HomeCarousel from "./HomeCarousel";
import Achevments from "./Achevments";
import Partners from "./Partners";
import PortfolioDemo from "./PortfolioDemo";
import BlogDemo from "./BlogDemo";
import Vision from "./Vision";

const HomePage = () => {
  return (
    <Box>
      <Container maxWidth="xl">
        <HomeCarousel />
        <Vision />
        <PortfolioDemo />
        <Partners />

        <BlogDemo />
        <Achevments />
      </Container>
    </Box>
  );
};

export default HomePage;
