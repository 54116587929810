import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Login from "../auth/Login";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AdminModal({
  handleLogin,
  handleOpenAdmin,
  openAdminModal,
  handleCloseAdmin,
  isLoginError,
  handleCloseAlert
}) {
  
  return (
    <React.Fragment>
      <BootstrapDialog
        fullScreen
        onClose={handleCloseAdmin}
        aria-labelledby="customized-dialog-title"
        open={openAdminModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Admin Login
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseAdmin}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Login handleLogin={handleLogin} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleCloseAdmin}>
            Login
          </Button> */}
          <Snackbar
            open={isLoginError}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              variant="outlined"
              sx={{ width: "100%" }}
            >
              email or password is incorrect
            </Alert>
          </Snackbar>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
