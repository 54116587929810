import { Box } from "@mui/material";
import React from "react";
import AchevmentsList from "./AchevmentsList";

const Achevments = () => {
  return (
    <Box sx={{ mb: 20, bgcolor: "background.paper", p: 2 }}>
      <AchevmentsList />
    </Box>
  );
};

export default Achevments;
