import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FooterSubNavigationAccordeon from "./FooterSubNavigationAccordeon";
import { useLocation } from "react-router-dom";

const navigationItems = [
  // { name: "home page", path: "homePage" },
  { name: "about", path: "about" },
  { name: "services", path: "services" },
  {
    name: "projects",
    path: "projects",
    hasSubPages: true,
    subPages: [
      { name: "interior", path: "projects" },
      { name: "architecture", path: "projects" },
      { name: "new projects", path: "projects" },
    ],
  },
  {
    name: "media",
    path: "media",
    hasSubPages: true,
    subPages: [
      { name: "blog", path: "blog" },
      { name: "gallery", path: "gallery" },
    ],
  },

  {
    name: "education",
    path: "education",
    hasSubPages: true,
    subPages: [{ name: "generation 21", path: "generation" }],
  },
  { name: "career", path: "career" },
  { name: "contacts", path: "contacts" },
];

const FooterNavigation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [pathname]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography sx={{ mb: 2, textAlign: "left" }} variant="h6">
        navigation
      </Typography>

      <List>
        {navigationItems.map((navigationItem, index) => {
          return !navigationItem.subPages ? (
            <ListItem disableGutters>
              <Link
                to={navigationItem.path}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <ListItemText
                  primary={navigationItem.name}
                  //   secondary={secondary ? "Secondary text" : null}
                />
              </Link>
            </ListItem>
          ) : (
            <FooterSubNavigationAccordeon navigationItem={navigationItem} />
            // <List>
            //   {navigationItem.subPages.map((subPage, index) => {
            //     return (
            //       <ListItem>
            //         <Link
            //           to={subPage.path}
            //           style={{ color: "#fff", textDecoration: "none" }}
            //         >
            //           <ListItemText
            //             primary={subPage.name}
            //             //   secondary={secondary ? "Secondary text" : null}
            //           />
            //         </Link>
            //       </ListItem>
            //     );
            //   })}
            // </List>
          );
        })}
      </List>
    </Box>
  );
};

export default FooterNavigation;
