import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import PortfolioDemoCarousel from "./PortfolioDemoCarousel";
import PortfolioList from "./PortfolioList";
import { Link } from "react-router-dom";

const PortfolioDemo = () => {
  const itemsPerPage = 4;

  const [itemsCount, setItemsCount] = useState(itemsPerPage);

  const handleLoadMoreItems = () => {
    setItemsCount(itemsCount + itemsPerPage);
  };

  return (
    <Box sx={{ mb: 20, display: "flex", flexDirection: 'column',  }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 10 }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontWeight: 500,
            borderBottomWidth: 1,
            borderBottomColor: "primary.main",
          }}
        >
          Portfolio
        </Typography>
        <Link
          to={"/projects"}
          style={{
            textDecoration: "none",
            color: "text.primary",
            padding: 0,
            margin: 0,
          }}
        >
          <Button color="primary" variant="contained" sx={{ px: 4 }}>
            View All
          </Button>
        </Link>
        {/* <Button color="primary" variant="contained" sx={{ px: 4 }}>
          View All
        </Button> */}
      </Box>
      {/* <PortfolioDemoCarousel /> */}
      <PortfolioList itemsCount={itemsCount} />
      <Button
        color="primary"
        variant="contained"
        sx={{ px: 4, alignSelf: "flex-start" }}
        onClick={handleLoadMoreItems}
      >
        Load More
      </Button>
    </Box>
  );
};

export default PortfolioDemo;
