import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, IconButton, ImageListItemBar } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Navigate } from "react-router-dom";

const projectsData = [
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },

      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },

      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },

      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },

      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },

      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },

      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },

      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },

      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },

      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },

      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
];

export default function PortfolioList({ itemsCount }) {
  const handleOpenProject = () => {};

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" }, mt: 10 }}>
        <ImageList
          sx={{
            width: "100%",
            // height: "auto",
            mb: 6,
          }}
          cols={4}
        //   rowHeight={"auto"}
          gap={40}
        >
          {projectsData.slice(0, itemsCount).map((project, index) => (
            <ImageListItem sx={{mb: 4}} key={project.img} onClick={handleOpenProject}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${
                  project.images[index]
                    ? project.images[index].img
                    : project.images[0].img
                }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${project.images[0].img}?w=164&h=164&fit=crop&auto=format`}
                alt={project.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={project.title}
                // subtitle={item.author}
                // position="below"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {/* mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <ImageList
          sx={{
            width: "100%",
            height: "auto",
            // display: { xs: "block", md: "none" },
            mb: 6,
          }}
          cols={1}
        //   rowHeight={"auto"}
          gap={40}
        >
          {projectsData.slice(0, itemsCount).map((project, index) => (
            <ImageListItem key={project.img} onClick={handleOpenProject}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${
                  project.images[index]
                    ? project.images[index].img
                    : project.images[0].img
                }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${project.images[0].img}?w=164&h=164&fit=crop&auto=format`}
                alt={project.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={project.title}
                // position="below"
                // subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
