import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


const ServicesItem = ({ service }) => {
  return (
    <Box sx={{ mb: 20 }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              mb: 10,
            }}
          >
            <Typography
              variant={"h5"}
              sx={{ textAlign: "right", mb: 4, fontWeight: 500 }}
            >
              {service.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "left", mb: 4, pr: 10 }}
            >
              {service.text}
            </Typography>
            <Link 
              to={"/projects"}
              style={{
                textDecoration: "none",
                color: "text.primary",
                padding: 0,
                margin: 0,
              }}
            >
              <Button color="primary" variant="contained" sx={{ px: 4 }}>
                View All
              </Button>
            </Link>
            {/* <Button color="primary" variant="contained" sx={{ px: 4 }}>
              View Portfolio
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            // srcSet={`${service.imageURL}}
            style={{ width: "100%" }}
            src={`${service.imageURL}`}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesItem;
