import { Box } from "@mui/material";
import React from "react";
import PartnersCarousel from "./PartnersCarousel";
import PartnersList from "./PartnersList";

const Partners = () => {
  return (
    <Box sx={{mb: 20, bgcolor: "background.default", p:2}}>
      <PartnersCarousel />
      {/* <PartnersList /> */}
    </Box>
  );
};

export default Partners;
