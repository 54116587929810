import React from "react";
import { Button, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
// import { useSelector } from "react-redux";
import LoginForm from "./LoginForm";

const Login = ({handleLogin}) => {
//   const isLoggingIn = useSelector((state) => state.auth.login.isLoggingIn);

  return (
    <Paper
      sx={{
        // width: "30%",
        // height: "90%",
        display: "flex",
        flexDirection: "column",
        boxShadow: 0,
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      {/* <Typography variant="h4" sx={{ mt: 0, mb: 3 }}>
        Admin Log in
      </Typography> */}
      <LoginForm handleLogin={handleLogin} />
      {/* <Button sx={{ mt: 5 }}>
        <Link to={"/register"} style={{ color: "#000" }}>
          Create New Account
        </Link>
      </Button> */}
      {/* {isLoggingIn ? <CircularProgress sx={{ color: "primary.main" }} /> : null} */}
    </Paper>
  );
};

export default Login;
