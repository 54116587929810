import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServicesItem from "./ServicesItem";

const services = [
  {
    title: "architecture",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis justo diam, in fermentum urna vestibulum nec. Nullam blandit metus sem, congue sollicitudin risus pharetra et. Proin ac justo lacinia, viverra risus consequat, tempor purus. Phasellus at tempus eros. Cras convallis tempor nunc at accumsan. Nam vel urna sapien. Suspendisse ac mi vulputate, tempor nisi a, vulputate mauris. Aliquam neque nulla, dictum a ante nec, finibus tristique arcu.",
    imageURL: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
  },

  {
    title: "interior",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis justo diam, in fermentum urna vestibulum nec. Nullam blandit metus sem, congue sollicitudin risus pharetra et. Proin ac justo lacinia, viverra risus consequat, tempor purus. Phasellus at tempus eros. Cras convallis tempor nunc at accumsan. Nam vel urna sapien. Suspendisse ac mi vulputate, tempor nisi a, vulputate mauris. Aliquam neque nulla, dictum a ante nec, finibus tristique arcu.",
    imageURL: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
  },
];

const ServicesPage = () => {
  return (
    <Box sx={{mt: 10}}>
      <Container maxWidth={"xl"}>
        <Typography variant="h4" sx={{ textAlign: "left", mb: 10 , fontWeight: 500}}>
          Services
        </Typography>

        <Grid container>
          {services.map((service, index) => {
            return (
              <Grid item xs={12}>
                <ServicesItem service={service} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesPage;
