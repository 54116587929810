// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { EffectCreative } from "swiper/modules";
import bgg1 from "../../assets/homepage/bgg1.jpg";
import bgg2 from "../../assets/homepage/bgg2.jpg";
import bgg3 from "../../assets/homepage/bgg3.jpg";
import bgg4 from "../../assets/homepage/bgg4.jpg";
import bgg5 from "../../assets/homepage/bgg5.jpg";
import bgg6 from "../../assets/homepage/bgg6.jpg";

// Import Swiper styles
import "swiper/css";
import { Box, Button, Container, Typography } from "@mui/material";

const images = [bgg4, bgg5, bgg6];

export default  () => {
  return (
    <Box sx={{ height: "100vh", mb: 20 }}>
      <Swiper
        speed={3000}
        effect={"fade"}
        // creativeEffect={{
        //   prev: {
        //     shadow: true,
        //     translate: [0, 0, -400],
        //   },
        //   next: {
        //     translate: ["100%", 0, 0],
        //   },
        // }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ height: "100%" }}
      >
        {images.map((image, index) => {
          return (
            <SwiperSlide
              style={{
                height: "100%",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "cemter",
                  justifyContent: "cemter",
                }}
              >
                <Container
                  maxWidth={"xs"}
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant={"body1"} sx={{ color: "#fff", mb: 4 }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Aperiam est quos sint voluptate amet, provident aut quisquam
                    deleniti iste quae eaque recusandae cum consequatur error
                    quis unde voluptates dolores pariatur.
                  </Typography>
                  {/* <Typography variant={"h5"} sx={{ color: "#fff", mb: 2 }}>
                    lorem osum dolor ipsum
                  </Typography>
                  <Button sx={{ bgcolor: "#222", color: "#fff" }}>
                    view project
                  </Button> */}
                </Container>
              </Box>
            </SwiperSlide>
          );
        })}
        {/* ... */}
      </Swiper>
    </Box>
  );
};
