import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import NavSubMenu from "./NavSubMenu";
import logo2 from "../../assets/logo2.png";
import { Link } from "react-router-dom";
import NavigationSubAccordeon from "./NavigationSubAccordeon";

const pages = [
  // { name: "home page", path: "homePage" },
  { name: "about", path: "about" },
  { name: "services", path: "services" },
  {
    name: "Projects",
    path: "projects",
    hasSubPages: true,
    subPages: [
      { name: "interior", path: "projects" },
      { name: "architecture", path: "projects" },
      { name: "new projects", path: "projects" },
    ],
  },
  {
    name: "media",
    path: "media",
    hasSubPages: true,
    subPages: [
      { name: "blog", path: "blog" },
      { name: "gallery", path: "gallery" },
    ],
  },

  {
    name: "education",
    path: "education",
    hasSubPages: true,
    subPages: [{ name: "generation 21", path: "generation" }],
  },
  { name: "career", path: "career" },
  { name: "contacts", path: "contacts" },
];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavigationBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: "background.paper", boxShadow: 0 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              color: "#ff2800",
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#ff2800",
              textDecoration: "none",
            }}
          >
            ARCHITECTUM
          </Typography> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              alignItems: "center",
            }}
          >
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "text.primary",
                padding: 0,
                margin: 0,
              }}
            >
              <img src={logo2} alt="logo architectum" style={{ height: 34 }} />
              {/* <Button color="primary" variant="contained" sx={{ px: 4 }}>
                View All
              </Button> */}
            </Link>

            <IconButton
              size="large"
              sx={{ ml: "auto" }}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "text.primary" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              fullwidth
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", width: "100vw" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={index}
                  // onClick={handleCloseNavMenu}
                  sx={{ mx: 4 }}
                >
                  {!page.hasSubPages ? (
                    <Link
                      to={page.path}
                      style={{ textDecoration: "none", color: "text.primary" }}
                    >
                      <Button
                        size="large"
                        key={index}
                        onClick={handleCloseNavMenu}
                        sx={{
                          // my: 2,
                          // mx: 2,
                          color: "#222",
                          fontWeight: 600,
                          // display: "block",
                          textTransform: "lowercase",
                        }}
                      >
                        {page.name}
                      </Button>
                      {/* <Typography
                      textAlign="center"
                      sx={{ textTransform: "lowercase" }}
                    >
                      {page.name}
                    </Typography> */}
                    </Link>
                  ) : (
                    <NavigationSubAccordeon navigationItem={page} />
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 4,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#ff2800",
              textDecoration: "none",
            }}
          >
            ARCHITECTUM
          </Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", alignItems: "center" },
            }}
          >
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "text.primary",
                padding: 0,
                margin: 0,
              }}
            >
              <img src={logo2} alt="logo architectum" style={{ height: 34 }} />
              {/* <Button color="primary" variant="contained" sx={{ px: 4 }}>
                View All
              </Button> */}
            </Link>

            {pages.map((page, index) => {
              return !page.hasSubPages ? (
                <Link to={page.path} style={{ textDecoration: "none" }}>
                  <Button
                    key={index}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      mx: 2,
                      color: "#222",
                      display: "block",
                      textTransform: "lowercase",
                    }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ) : (
                <NavSubMenu
                  subPages={page.subPages}
                  page={page}
                  //  handleCloseNavSubMenu={handleCloseNavSubMenu}
                  //  anchorElNavSubMenu={anchorElNavSubMenu}
                />
              );
            })}
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavigationBar;
