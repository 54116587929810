import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const AchevmentItem = ({ achevment }) => {
  return (
    <Box sx={{ mb: 10, bgcolor: "background.paper", boxShadow: 0, p: 4 }}>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
      </style>
      {/* <Container maxWidth={"md/"}> */}
      <Typography
        variant="h5"
        sx={{
          // fontFamily: "Roboto, sans-serif",

          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: 400,
          color: "text.secondary",
          m: 2,
        }}
      >
        {achevment.description}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "Roboto, sans-serif",

          color: "text.secondary",
          fontWeight: "600",
          textAlign: "center",
          m: 2,
        }}
      >
        {achevment.count}
      </Typography>
      {/* </Container> */}
    </Box>
  );
};

export default AchevmentItem;
