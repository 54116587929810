import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";



const contactItems = [
    "info@architectum.am",
    "hr@architectum.am",
]


// const projectItems = [

// ]




export default function FooterContactsList() {
 

  return (
    <Box >
      <Typography sx={{ mt: 4, mb: 2, textAlign: 'left', color: 'primary.contrastText' }} variant="h6" >
        contacts
      </Typography>

      <List>
        {contactItems.map((contact, index) => {
            return (
              <ListItem disableGutters>
                <ListItemText
                  primary={contact}
                //   secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
            );
        })}
        
      </List>

    </Box>
  );
}
