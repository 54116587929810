import { Box, Container, Divider, Grid, Typography } from "@mui/material";
// import { WorkingHoursTable } from "features/contacts/components/WorkingHoursTable";
// import ContactsGoogleMap from "features/contacts/containers/ContactsGoogleMap";
import React from "react";
// import NavBarDrawer from "shared/containers/NavBarDrawer";
import FooterWebsiteInfo from "./FooterWebsiteInfo";
import FooterBrand from "./FooterBrand";
import FooterContactsList from "./FooterContactsList";
import FooterNavigation from "./FooterNavigation";
// import DealAuto23NavBarList from "shared/containers/DealAuto23NavBarList";
import logoDark from "../../assets/logo-dark.jpg";
import FooterSocialBar from "./FooterSocialBar";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },

          // display: "flex",
          // justifyContent: "center",
          // py: 5,
          // my: 8,
          // boxShadow: 20,
          // boxSizing: "border-box",
          bgcolor: "#111",
          color: "#fff",
          py: 10,
        }}
      >
        <Container maxWidth="xl" sx={{ boxShadow: 0 }}>
          <Grid
            container
            //   maxWidth="xl"
            justifyContent="space-between"
            alignContent="center"
            // rowSpacing={10}
            // columnSpacing={0}
          >
            <Grid item xs={12}>
              <Box sx={{ mb: 10 }}>
                <img
                  src={logoDark}
                  alt="logo architectum"
                  style={{ width: 300 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FooterContactsList />
              <Box>
                <Typography
                  sx={{ mt: 4, mb: 2, textAlign: "left" }}
                  variant="h6"
                  component="div"
                >
                  address
                </Typography>

                <Typography sx={{ textAlign: "left" }}>
                  kievyan most:), Yerevan, Armenia
                </Typography>
              </Box>
              <FooterSocialBar />
            </Grid>
            {/* <Grid xs={12} item md="auto">
            <Divider
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              orientation="vertical"
            />
            <Divider
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
              orientation="horizontal"
            />
          </Grid> */}

            {/* <Grid item md={3}></Grid> */}

            {/* <Grid xs={12} item md="auto">
            <Divider
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              orientation="vertical"
            />
            <Divider
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
              orientation="horizontal"
            />
          </Grid> */}

            <Grid item xs={12} sm={5} justifyContent="end">
              <FooterNavigation />
            </Grid>

            {/* <Grid item xs={12} md={12}>
            <FooterWebsiteInfo />
          </Grid> */}
            {/* <Grid item sm={6}>
          </Grid> */}

            <Grid item xs={12}>
              <Typography sx={{ mt: 10, mb: 2 }}>
                2024 architectum. All right.
              </Typography>
              <Typography>made by David Grig</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* mobile */}

      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          // justifyContent: "center",
          // py: 5,
          // my: 8,
          // boxShadow: 20,
          // boxSizing: "border-box",
          bgcolor: "#111",
          color: "#fff",
          py: 10,
          px: 2,
        }}
      >
        <Container maxWidth="md" sx={{ boxShadow: 0 }}>
          <Grid
            container
            // maxWidth="xs"
            justifyContent="flex-start"
            alignitems="center"
            // rowSpacing={10}
            // columnSpacing={0}
          >
            <Grid item xs={12}>
              <Box sx={{ mb: 10 }}>
                <img
                  src={logoDark}
                  alt="logo architectum"
                  style={{ width: 300 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FooterContactsList />
              <Box>
                <Typography
                  sx={{ mt: 4, mb: 2, textAlign: "left" }}
                  variant="h6"
                  component="div"
                >
                  address
                </Typography>

                <Typography sx={{ textAlign: "left" }}>
                  kievyan most:), Yerevan, Armenia
                </Typography>
              </Box>
              <FooterSocialBar />
            </Grid>
            {/* <Grid xs={12} item md="auto">
            <Divider
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              orientation="vertical"
            />
            <Divider
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
              orientation="horizontal"
            />
          </Grid> */}

            {/* <Grid item md={3}></Grid> */}

            {/* <Grid xs={12} item md="auto">
            <Divider
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              orientation="vertical"
            />
            <Divider
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
              orientation="horizontal"
            />
          </Grid> */}

            <Grid item sx={12} sm={6}>
              <FooterNavigation />
            </Grid>

            {/* <Grid item xs={12} md={12}>
            <FooterWebsiteInfo />
          </Grid> */}
            {/* <Grid item sm={6}>
          </Grid> */}

            <Grid item xs={12}>
              <Typography sx={{ mt: 10, mb: 2 }}>
                © 2024 architectum. All rights reserved.
              </Typography>
              <Typography>made by David Grig</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
