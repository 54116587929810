import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProjectsList from "./ProjectsList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const projectsData = [
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },

      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },

      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },

      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },

      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },

      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },

      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },

      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },

      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },

      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",

       
      },
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },

      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "new-project",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "architecture",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
  {
    category: "interior",
    title: "Lorem, ipsum dolor sit amet",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero dolorem cumque ratione, ex aspernatur tenetur consequuntur voluptas officia sequi architecto totam, quod rem dicta vel officiis. Porro repudiandae dolorem natus dolor praesentium pariatur magnam quasi voluptate rem accusamus? Earum sequi consectetur voluptas nesciunt commodi rem quasi possimus tempore odio harum qui, excepturi eius sed sunt? Laudantium nam sunt quo? Quibusdam rem pariatur quisquam repellat et nostrum. Velit ad dolor iste!",
    images: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
    additionalDetails: {
      location: "lorem ipsum dolor 14",
      year: 2021,
      status: "completed",
      area: "confidential",
      designTeam: "lorem loren lorem",
    },
  },
];

const projectCategories = ["architecture", "interior", "new-project"];

export default function ProjectsTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar
        position="static"
        sx={{ bgcolor: "background.paper", boxShadow: 0 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="text.primary"
          variant="standard"
          aria-label="tabs"
          sx={{
            bgcolor: "background.paper",
            color: "text.primary",
            boxShadow: 0,
          }}
        >
          {projectCategories.map((category, index) => {
            return <Tab label={category} sx={{textTransform: "capitalize"}} {...a11yProps(0)} />;
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {projectCategories.map((category, index) => {
          return (
            <TabPanel value={value} index={index} dir={theme.direction}>
              <ProjectsList
                projects={projectsData.filter(
                  (project, index) => project.category === category
                )}
              />
            </TabPanel>
          );
        })}
      </SwipeableViews>
    </Box>
  );
}
