import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, IconButton, ImageListItemBar } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Navigate } from "react-router-dom";

export default function ProjectsList({ projects }) {
  const handleOpenProject = () => {};

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <ImageList
          sx={{
            width: "100%",
            height: "auto",
            mb: 20,
          }}
          cols={4}
          rowHeight={"auto"}
          gap={24}
        >
          {projects.map((project, index) => (
            <ImageListItem key={project.img} onClick={handleOpenProject}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${
                  project.images[index]
                    ? project.images[index].img
                    : project.images[0].img
                }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${project.images[0].img}?w=164&h=164&fit=crop&auto=format`}
                alt={project.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={project.title}
                // subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {/* mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <ImageList
          sx={{
            width: "100%",
            height: "auto",
            // display: { xs: "block", md: "none" },
            mb: 20,
          }}
          cols={1}
          rowHeight={"auto"}
          gap={40}
        >
          {projects.map((project, index) => (
            <ImageListItem key={project.img} onClick={handleOpenProject}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${
                  project.images[index]
                    ? project.images[index].img
                    : project.images[0].img
                }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${project.images[0].img}?w=164&h=164&fit=crop&auto=format`}
                alt={project.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={project.title}
                // subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
