import * as React from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function CustomPagination({page,handlePageChange, itemsPerPage, count}) {
  

  return (
    <Stack spacing={2} sx={{mb: 10}}>
      <Typography>Page: {page}</Typography>
      <Pagination count={Math.floor((count / itemsPerPage) + 1)} page={page} color="primary" onChange={handlePageChange} />
    </Stack>
  );
}
