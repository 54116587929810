import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Container,
  Button,
} from "@mui/material";
// import CountdownTimerLayout from "./CountDownTimerLayout";
import Social from "./Social";
import Login from "../auth/Login";
import firebase from "../firebase";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import Architectum from "../Architectum";
import AdminLogin from "./AdminModal";
// import { Box } from "@mui/material";
const auth = getAuth();

const UnderConstructionPage = () => {
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [admin, setAdmin] = useState(null);
    const [isLoginError, setIsLoginError] = React.useState(false);

  const handleOpenAdmin = () => {
    setOpenAdminModal(true);
  };

  const handleCloseAdmin = () => {
    setOpenAdminModal(false);
  };

  const handleLogin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const admin = userCredential.user;
        setAdmin(admin);
        setIsLoggedIn(true);
        handleCloseAdmin();
        console.log(admin);
        // ...
      })
      .catch((error) => {
        setAdmin(null);
        setIsLoggedIn(false);
        setIsLoginError(true);

        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsLoginError(false);
  };

  return (
    <Box>
      {!isLoggedIn ? (
        <Box sx={{ height: "100vh" }}>
          <Container
            maxWidth={"sm"}
            sx={{
              height: "100%",
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <style>
              @import
              url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap')
            </style>
            {/* <Grid
          container
          maxWidth="xs"
          sx={{ height: "100%" }}
          justifyContent={"center"}
          alignItems={"space-between"}
        > */}
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    textTransform: "uppercase",
                    fontFamily: "Raleway, sans-serif",
                    mb: 6,
                  }}
                >
                  Coming Soon
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Raleway, sans-serif" }}
                >
                  We are currently developing website. We'll be here soon.
                </Typography>
              </Box>
              <Box>
                <Social />
              </Box>
              {/* </Grid> */}
            </Box>
          </Container>

          <Container
            maxWidth={"xs"}
            sx={{
              height: "100%",
              display: {
                xs: "flex",
                sm: "none",
              },
            }}
          >
            <style>
              @import
              url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap')
            </style>
            {/* <Grid
          container
          maxWidth="xs"
          sx={{ height: "100%" }}
          justifyContent={"center"}
          alignItems={"space-between"}
        > */}
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    textTransform: "uppercase",
                    fontFamily: "Raleway, sans-serif",
                    mb: 6,
                  }}
                >
                  Coming Soon
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Raleway, sans-serif" }}
                >
                  We are currently developing website. We'll be here soon.
                </Typography>
              </Box>
              <Box>
                <Social />
              </Box>

              {/* </Grid> */}
            </Box>
          </Container>
          <Button
            variant="outlined"
            color="info"
            sx={{
              ml: "auto",
              alignSelf: "flex-end",
              position: "absolute",
              bottom: 0,
              right: 0,
              color: "#222",
              m: 1
            }}
            onClick={handleOpenAdmin}
          >
            Admin Login
          </Button>
        </Box>
      ) : (
        <Architectum />
      )}
      {
        <AdminLogin
          handleLogin={handleLogin}
          handleOpenAdmin={handleOpenAdmin}
          handleCloseAdmin={handleCloseAdmin}
          openAdminModal={openAdminModal}
          isLoginError={isLoginError}
          handleCloseAlert={handleCloseAlert}
        />
      }
    </Box>
  );
};

export default UnderConstructionPage;
