import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function NavigationSubAccordeon({ navigationItem }) {
  return (
    <Accordion
      //   disableGutters
      sx={{
        //  width: "max-content",
        boxShadow: 0,
        bgcolor: "background.paper"
      }}
    >
      <AccordionSummary
        // disableGutters
        sx={{ ml: 0, pl: 0 }}
        expandIcon={<ArrowDropDownIcon sx={{}} />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Button
          variant="text"
          sx={{ textTransform: "lowercase", color: "text.primary" }}
        >
          {navigationItem.name}
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {navigationItem.subPages.map((subNavigationItem, index) => {
            return (
              <ListItem>
                <Link
                  to={subNavigationItem.path}
                  style={{ textDecoration: "none", color: "text.primary" }}
                >
                  <Button
                    variant="text"
                    // size="large"
                    sx={{ color: "text.primary", textTransform: "lowercase" }}
                  >
                    {subNavigationItem.name}
                  </Button>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
