import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

export default function FooterSubNavigationAccordeon({ navigationItem }) {
  return (
    <Accordion
      disableGutters
      sx={{ bgcolor: "#111", pl: 0, color: "#fff", width: "max-content" }}
    >
      <AccordionSummary
        disableGutters
        sx={{ ml: 0, pl: 0 }}
        expandIcon={<ArrowDropDownIcon sx={{ color: "#fff" }} />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography>{navigationItem.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {navigationItem.subPages.map((subNavigationItem, index) => {
            return (
              <ListItem disableGutters>
                <Link
                  to={subNavigationItem.path}
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <ListItemText
                    primary={subNavigationItem.name}
                    //   secondary={secondary ? "Secondary text" : null}
                  />
                </Link>
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
