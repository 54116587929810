import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { IconButton, Link } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Social() {
  return (
    <div>
      <Stack direction="row" spacing={2}>
        <Item sx={{ boxShadow: 0 }}>
          <IconButton>
            <Link
              target="_blank"
              href="https://www.facebook.com/architectumarmenia?mibextid=ZbWKwL"
            >
              <FacebookIcon sx={{ color: "text.primary" }} />
            </Link>
          </IconButton>
        </Item>
        <Item sx={{ boxShadow: 0 }}>
          <IconButton>
            <Link
              target="_blank"
              href="https://www.instagram.com/architectum_armenia?igsh=MWgzeXk3cDNjZ3BiOA=="
            >
              <InstagramIcon sx={{ color: "text.primary" }} />
            </Link>
          </IconButton>
        </Item>
        <Item sx={{ boxShadow: 0 }}>
          <IconButton>
            <Link
              target="_blank"
              href="https://www.linkedin.com/company/architectum-armenia/"
            >
              <LinkedInIcon sx={{ color: "text.primary" }} />
            </Link>
          </IconButton>
        </Item>
      </Stack>
    </div>
  );
}
