import React, { useState } from "react";
import NavigationBar from "./navigation/NavigationBar";
import GalleryPage from "../features/gallery/GalleryPage";
import ServicesPage from "../features/services/ServicesPage";
import HomePage from "../features/home/HomePage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  blue,
  green,
  yellow,
  red,
  orange,
  deepOrange,
  deepPurple,
  amber,
} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { Box, Container, Divider, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { lime } from "@mui/material/colors";
import Footer from "./footer/Footer";
import ProjectsPage from "../features/projects/ProjectsPage";
import AboutPage from "../features/about/AboutPage";
import BlogPage from "../features/blog/BlogPage";


export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export const Architectum = () => {
  
  const [mode, setMode] = React.useState("light");



  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchAutos());
    return () => {};
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme(
        mode === "light"
          ? {
              palette: {
                mode: "light",
                primary: {
                  // main: "#09af00",
                  // main: "#d50000",
                  // main: "#ff2800",
                  // main: "#1b205a",
                  // main: "#ff3d00",
                  // contrastText: "rgba(255,255,255,1)",
                  main: "#D20000",
                  // main: "#2196f3",
                  // contrastText: "rgba(255,255,255,0.99)",
                  contrastText: "#eee",
                },
                secondary: {
                  main: "#ffeb3b",
                },
                background: {
                  paper: "#ffffff",
                  default: "#F8F8F8",
                },
              },

              typography: {
                // fontSize: 15,

                h1: {
                  fontFamily: "Raleway, sans-serif",
                },

                h2: {
                  fontFamily: "Raleway, sans-serif",

                  // fontWeight: 500,
                },

                h3: {
                  fontFamily: "Raleway, sans-serif",

                  // fontWeight: 600,
                },

                h4: {
                  fontFamily: "Raleway, sans-serif",
                  // fontWeight: 300,
                },

                h5: {
                  fontFamily: "Raleway, sans-serif",
                  // fontWeight: 400,
                },

                h6: {
                  fontFamily: "Raleway, sans-serif",
                },

                button: {
                  fontFamily: "Raleway, sans-serif",
                  fontWeight: 600,
                },
              },
            }
          : {
              palette: {
                mode: "dark",
                primary: {
                  // main: "#FFEB3B",
                  // // contrastText: "rgba(0, 0, 0, 0.87)",
                  // main: "#09af00",
                  // main: "#ffc107", // good color
                  main: "#D20000",
                  // main: "#2196f3",
                  // contrastText: "rgba(255,255,255,0.99)",
                  contrastText: "#eee",
                },
                secondary: {
                  main: "#ffeb3b",
                },
                background: {
                  default: "#292929",
                  paper: "#1f1f1f",
                },
              },

              typography: {
                h1: {
                  fontFamily: "Raleway, sans-serif",
                  // fontWeight: 300,
                },

                h2: {
                  fontFamily: "Raleway, sans-serif",
                },

                button: {},
              },
            }
      ),
    [mode]
  );

  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>

        
          <Router>
            <Box
              sx={{
                width: "100%",
                // height: "100%",
                // bgcolor: "background.paper",
              }}
            >
              <style>
                @import
                url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap')
              </style>

              <NavigationBar />
              <Box>
                <Routes>
                  <Route index  element={<HomePage />}></Route>
                  <Route path={"/services"} element={<ServicesPage />}></Route>
                  <Route path={"/gallery"} element={<GalleryPage />}></Route>
                  <Route path={"/blog"} element={<BlogPage />}></Route>
                  <Route path={"/about"} element={<AboutPage />}></Route>{" "}
                  <Route path={"/projects"} element={<ProjectsPage />}></Route>
                </Routes>
                {/* <GalleryPage /> */}
              </Box>
              <Footer mode={mode} />
            </Box>
          </Router>
        
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

// const Architectum = () => {
//   return (
//     <Box>
//       <NavigationBar />
//       <HomePage />
//       <GalleryPage />
//     <ServicesPage />
//     </Box>
//   );
// };

export default Architectum;
