// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_1hwxgNJJnl5N60Uc-Up3ViuFebFwKpY",
  authDomain: "architectum-a1e71.firebaseapp.com",
  projectId: "architectum-a1e71",
  storageBucket: "architectum-a1e71.appspot.com",
  messagingSenderId: "458482151154",
  appId: "1:458482151154:web:65c01cee0446736c74ea6b",
  measurementId: "G-KN9VT7T9WM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
