import visionBG from "../../assets/homepage/visionBG.jpg";

import { Box, Button, Container, Typography, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Vision = () => {
  return (
    <Box sx={{ mb: 20, bgcolor: "background.paper", p: 2 }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "left", mb: 10, fontWeight: 500 }}
      >
        Vision
      </Typography>
      <Grid container rowSpacing={10}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="body1" sx={{ textAlign: "left", mb: 4, pr: 16 }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil,
              tempore. Mollitia eum quibusdam dicta natus suscipit praesentium,
              architecto qui magni adipisci rem, doloribus vel animi sit
              accusantium totam? Quos porro eligendi esse distinctio, obcaecati
              praesentium mollitia at perspiciatis reprehenderit id quibusdam
              commodi vitae voluptatum numquam inventore quod molestiae
              laudantium iste.
            </Typography>
            <Link
              to={"/projects"}
              style={{
                textDecoration: "none",
                color: "text.primary",
                padding: 0,
                margin: 0,
              }}
            >
              <Button color="primary" variant="contained" sx={{ px: 4 }}>
                View All
              </Button>
            </Link>
            {/* <Button
              color="primary"
              variant="contained"
              sx={{ px: 4 }}
            >
              View Portfolio
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "60vh",
              backgroundImage: `url(${visionBG})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></Box>
          {/* <img
            // srcSet={`${service.imageURL}}
            style={{ width: "100%" }}
            src={`${visionBG}`}
            loading="lazy"
          /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Vision;
