import logo from "./logo.svg";
import "./App.css";
import Architectum from "./shared/Architectum";
import Login from "./shared/auth/Login";
import UnderConstructionPage from "./shared/beta/UnderConstructionPage";

function App() {
  return (
    <div className="App">
      {/* <Architectum /> */}
      <UnderConstructionPage />

    </div>
  );
}

export default App;
