import { Box, Grid } from "@mui/material";
import React from "react";
import AchevmentItem from "./AchevmentItem";

const achevments = [
  {
    description: "professional employees",
    count: "50+",
  },
  {
    description: "successful projects done",
    count: "500+",
  },
  {
    description: "sleepless hours spent",
    count: "50k",
  },
  {
    description: "cups of coffee employees",
    count: "500k",
  },
];

const AchevmentsList = () => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      columnSpacing={4}
    >
      {achevments.map((achevment, index) => {
        return (
          <Grid item md={6} sx={{}}>
            <AchevmentItem achevment={achevment} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AchevmentsList;
