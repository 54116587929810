import { Box, Container, Pagination, Typography } from "@mui/material";
import React from "react";
import GalleryList from "./GalleryList";
import CustomPagination from "./CustomPagination";

const galleriesData = [
  {
    title: "lorem ipsum ",
    data: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
  },
  {
    title: "lorem ipsum ",
    data: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
  },
  {
    title: "lorem ipsum ",
    data: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
  },
  {
    title: "lorem ipsum ",
    data: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
  },
  {
    title: "lorem ipsum ",
    data: [
      {
        img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
        title: "Bed",
      },
      {
        img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
        title: "Kitchen",
      },
      {
        img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
        title: "Sink",
      },
      {
        img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
        title: "Books",
      },
      {
        img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
        title: "Chairs",
      },
      {
        img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
        title: "Candle",
      },
      {
        img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
        title: "Laptop",
      },
      {
        img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
        title: "Doors",
      },
      {
        img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
        title: "Coffee",
      },
      {
        img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
        title: "Storage",
      },
      {
        img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
        title: "Coffee table",
      },
      {
        img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
        title: "Blinds",
      },
    ],
  },
];

const GalleryPage = () => {
  const itemsPerPage = 3;
  const [page, setPage] = React.useState(0);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Container maxWidth={"xl"}>
        {/* <Box sx={{display: "flex", alignItems: "center", flexDirection: "column",  }}> */}
        <Typography
          variant="h4"
          sx={{ my: 10, textAlign: "left", fontWeight: 500 }}
        >
          Gallery
        </Typography>

        {galleriesData
          .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
          .map((gallery, index) => {
            return (
              <Box>
                <Typography variant="h5" sx={{ mb: 4, textAlign: "left" }}>
                  {gallery.title}
                </Typography>
                <GalleryList galleryData={gallery.data} />
              </Box>
            );
          })}

        {/* </Box> */}
      </Container>
      <CustomPagination
        page={page}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        count={galleriesData.length}
      />
    </Box>
  );
};

export default GalleryPage;
