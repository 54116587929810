import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/material";

export default function GalleryList({ galleryData }) {
  

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <ImageList
          sx={{
            width: "100%",
            height: "auto",
            mb: 20,
          }}
          cols={3}
          rowHeight={"auto"}
          gap={24}
        >
          {galleryData.map((galleryItem) => (
            <ImageListItem key={galleryItem.img}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${galleryItem.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${galleryItem.img}?w=164&h=164&fit=crop&auto=format`}
                alt={galleryItem.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {/* // mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <ImageList
          sx={{
            width: "100%",
            height: "auto",
            mb: 20,
          }}
          cols={1}
          rowHeight={"auto"}
          gap={24}
        >
          {galleryData.map((galleryItem) => (
            <ImageListItem key={galleryItem.img}>
              <img
                //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",

                srcSet={`${galleryItem.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${galleryItem.img}?w=164&h=164&fit=crop&auto=format`}
                alt={galleryItem.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
